import '@utils/sentrySetup';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemePaletteModeContext, ThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Auth } from '@components/Auth';
import { getEnvironment } from '@utils/getEnvironment';

import { AppRoutes } from './AppRoutes';

import './App.css';

const queryClient = new QueryClient();

export const App = () => {
  const { auth0ClientId, auth0Domain, auth0Audience } = getEnvironment();

  const { themePaletteModeContextValue, theme } = useDeepUpTheme();

  return (
    <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0Audience,
          }}
          clientId={auth0ClientId ?? ''}
          domain={auth0Domain ?? ''}
        >
          <Auth>
            <QueryClientProvider client={queryClient}>
              <AppRoutes />
            </QueryClientProvider>
          </Auth>
        </Auth0Provider>
      </ThemeProvider>
    </ThemePaletteModeContext.Provider>
  );
};
