import { Box, Typography } from '@mui/material';

export const ErrorPage = ({
  errorText,
  statusCode = 404,
}: {
  errorText: string;
  statusCode?: number;
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#00A887',
      }}
    >
      <Typography color="text.primary" variant="h1">
        {statusCode}
      </Typography>
      <Typography color="text.primary" variant="h6">
        {errorText}
      </Typography>
    </Box>
  );
};
