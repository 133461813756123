export const getEnvironment = () => {
  return {
    auth0Audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    apiTrenchviewer: import.meta.env?.VITE_API_TRENCHVIEWER,
    sentryDsn: import.meta.env?.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env?.VITE_SENTRY_AUTH_TOKEN,
    appVersion: import.meta.env?.VITE_APP_VERSION,
    dev: import.meta.env?.DEV,
    mode: import.meta.env?.MODE,
  };
};
