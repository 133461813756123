import { useAuth0 } from '@auth0/auth0-react';
import type { ReactNode } from 'react';

export type AuthProps = {
  children: ReactNode;
};

export const Auth = ({ children }: AuthProps) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && error) {
    return <h3>Error: {error?.message}</h3>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch((e) =>
      console.error(e),
    );

    return null;
  }

  return children;
};
