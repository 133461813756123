import { useParams } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';
import { ErrorPage } from '@components/ErrorPage';
import { MeshViewer } from '@components/MeshViewer';

export const Page = () => {
  const { scanId } = useParams();

  if (!scanId) {
    return <ErrorPage errorText="ScanId is missing" />;
  }

  return (
    <ErrorBoundary fallback={<ErrorPage errorText="Error while loading 3D model" />}>
      <MeshViewer scanId={scanId} />
    </ErrorBoundary>
  );
};
