import { GlobalHeader } from '@deepup/global-header';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  const { t } = useTranslation();

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <GlobalHeader<'de'>
        language="de"
        languageSelectors={[]}
        showAppMenu={false}
        showThemeToggle
        showLanguageToggle={false}
        title={t('title')}
      />
      <Box component="div" sx={{ height: '80px' }} />
      <Outlet />
    </Box>
  );
};
