import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Error as ErrorRoot } from '@pages/error';
import { Page as PageHdMesh } from '@pages/hd-mesh/[scanId]/page';
import { Layout as LayoutRoot } from '@pages/layout';

export const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<LayoutRoot />}>
        <Route element={<PageHdMesh />} path="/hd-mesh/:scanId" />
        <Route element={<ErrorRoot />} path="*" />
      </Route>
    </Routes>
  </BrowserRouter>
);
