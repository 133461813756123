/* eslint-disable react/no-unknown-property */
import { Viewer3DHelp, Measurement, Viewer3D, DeepUpModel, BoundsOnLoad } from '@deepup/viewer3d';
import styled from '@emotion/styled';
import { Box, type Theme } from '@mui/material';
import { Html } from '@react-three/drei';
import { Suspense } from 'react';

import { ErrorPage } from '@components/ErrorPage';
import { FetchError, useApiHdMesh } from '@hooks/useApiHdMesh';
import { useThemeMode } from '@hooks/useThemeMode';

import { LoadingScreen } from './LoadingScreen';

export type MeshViewerProps = {
  className?: string;
  scanId: string;
};

const MeshViewerStyled = styled.div<{ theme?: Theme }>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const MeshViewer = ({ className, scanId }: MeshViewerProps) => {
  const { data: manifest, error, isLoading } = useApiHdMesh(scanId);
  const { isDarkMode } = useThemeMode();

  if (isLoading) {
    return <LoadingScreen isDarkMode={isDarkMode} />;
  }

  if (error) {
    if (!(error instanceof FetchError)) {
      console.error(error);
    }

    return (
      <ErrorPage
        errorText={
          error instanceof FetchError
            ? error.error.message
              ? error.error.message
              : error.error.error ?? 'Unknown error'
            : 'Unknown error'
        }
        statusCode={error instanceof FetchError ? error.res.status : undefined}
      />
    );
  }

  if (!manifest) {
    return <ErrorPage errorText="Manifest could not be loaded" />;
  }

  return (
    <MeshViewerStyled className={className}>
      <Measurement
        translations={{
          width: 'Breite',
          depth: 'Tiefe',
          step: 'Schritt',
          depthHelperText: 'Klicke auf den Grubenboden und die Oberkante, um die Tiefe zu messen.',
          widthHelperText: 'Klicke auf die linke und rechte Oberkante, um die Breite zu messen.',
          guideAnimationURL: (state, mode) =>
            `https://storage.googleapis.com/webassets-deepup-production/animations/${state}_guide_de_${mode}.mp4`,
        }}
      >
        {({ measurementView, measurementControls, onCanvasClick }) => (
          <>
            <Viewer3D
              canvasProps={{
                camera: { position: [0, 3, 3], rotation: [0, 0, 0], fov: 35 },
                onClick: onCanvasClick,
              }}
            >
              <color args={[isDarkMode ? '#121212' : '#dddddd']} attach="background" />
              <Suspense
                fallback={
                  <Html fullscreen>
                    <LoadingScreen isDarkMode={isDarkMode} />
                  </Html>
                }
              >
                <>
                  <BoundsOnLoad margin={1.0}>
                    <DeepUpModel manifest={manifest} />
                  </BoundsOnLoad>
                  {measurementView}
                </>
              </Suspense>
            </Viewer3D>
            <Box
              component="div"
              sx={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {measurementControls}
              <Viewer3DHelp
                translations={{
                  heading: 'Bedienung',
                  moveLabel: 'Ver-schieben',
                  moveChip: 'linke Maustaste',
                  zoomLabel: 'Zoomen',
                  zoomChip: 'Mausrad',
                  rotateLabel: 'Rotieren',
                  rotateChip: 'rechte Maustaste',
                }}
              />
            </Box>
          </>
        )}
      </Measurement>
    </MeshViewerStyled>
  );
};
